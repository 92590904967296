<template>
  <div>
    <div class="text-center mt-4">
      <h4>EVALUACIONES: {{ matter.code }} - {{ matter.name }}</h4>
    </div>
    <div class="d-flex">
      <b-button
        v-if="egressProfilesMatter"
        class="learning-suitcase-btn"
        size="sm"
        @click="LearningSuitcasePageTwo(egressProfilesMatter.matter)"
        ><b-icon-download class="mr-1 icon-download"></b-icon-download
        >EVALUACIÓN XLS</b-button
      >
    </div>
    <div class="learning-experiences-container">
      <b-table-simple
        bordered
        no-border-collapse
        small
        fixed
        responsive
        sticky-header="1000px"
        class="txt-custom"
      >
        <b-thead>
          <b-tr>
            <b-th class="text-center primary-header" colspan="12"
              >Evaluaciones</b-th
            >
          </b-tr>
          <b-tr>
            <b-th class="text-center align-middle secondary-header"
              >Tipo de evaluación</b-th
            >
            <b-th class="text-center align-middle secondary-header"
              >Resultado de aprendizaje</b-th
            >
            <b-th class="text-center align-middle secondary-header"
              >Indicadores de Logro</b-th
            >
            <b-th class="text-center align-middle secondary-header"
              >Situación evaluativa</b-th
            >
            <b-th
              class="text-center align-middle secondary-header"
              v-b-tooltip.v-secondary.noninteractive="
                'Descripción evaluación (Mencionar las características principales que configuran la actividad de evaluación: características principales del encargo, ejecución práctica, pruebas escritas o presentación)'
              "
              >Descripción evaluación
            </b-th>
            <b-th class="text-center align-middle secondary-header"
              >Agente evaluativo</b-th
            >
            <b-th class="text-center align-middle secondary-header"
              >Ponderación evaluación</b-th
            >
            <b-th class="text-center align-middle secondary-header"
              >Horas de Docencia Dirigida (pedagógicas)</b-th
            >
            <b-th class="text-center align-middle secondary-header"
              >Horas de Trabajo Autónomo Preparación y/o Ejecución
              (cronológicas)</b-th
            >
            <b-th class="text-center align-middle secondary-header"
              >Tipo de trabajo</b-th
            >
            <b-th class="text-center align-middle secondary-header"
              >Ambiente</b-th
            >
            <b-th class="text-center align-middle secondary-header"
              >Examinador/a</b-th
            >
          </b-tr>
        </b-thead>
        <b-tbody>
          <template v-if="learning_evaluation.length > 0">
            <b-tr
              v-for="matter_evaluation in learning_evaluation"
              :key="matter_evaluation.id"
            >
              <b-td class="text-left">
                {{ matter_evaluation.evaluation_type }}
              </b-td>
              <b-td>
                {{ matter_evaluation.learning_results }}
              </b-td>
              <b-td>
                {{ matter_evaluation.evaluation_criterias_micro }}
              </b-td>
              <b-td class="text-left">
                <div>
                  {{ matter_evaluation.evaluation_situations.join(", ") }}
                </div>
              </b-td>
              <b-td>
                <div
                  class="rich-text-content description-evaluation"
                  v-html="matter_evaluation.description"
                ></div>
              </b-td>
              <b-td class="text-left">
                <div>
                  {{ matter_evaluation.evaluative_agents.join(", ") }}
                </div>
              </b-td>
              <b-td> {{ matter_evaluation.weighing }}%</b-td>
              <b-td class="text-left">
                <div
                  v-for="(
                    pedagogical_hour, index
                  ) in matter_evaluation.pedagogical_hours.filter(
                    (x) => x.hours > 0
                  )"
                  :key="index"
                >
                  <p>
                    {{ pedagogical_hour.hours }} Hrs.
                    {{
                      pedagogical_hour.study_environment != null
                        ? pedagogical_hour.study_environment
                        : `Sin ${$getVisibleNames(
                            "mesh.studyenvironment",
                            false,
                            "Ambiente de Aprendizaje"
                          )}`
                    }}
                  </p>
                </div>
              </b-td>
              <b-td class="text-left">
                <div
                  v-for="(
                    chronological_hour, index
                  ) in matter_evaluation.chronological_hours.filter(
                    (x) => x.hours > 0
                  )"
                  :key="index"
                >
                  <p>
                    {{ chronological_hour.hours }} Hrs.
                    {{
                      chronological_hour.study_environment != null
                        ? chronological_hour.study_environment
                        : `Sin ${$getVisibleNames(
                            "mesh.studyenvironment",
                            false,
                            "Ambiente de Aprendizaje"
                          )}`
                    }}
                  </p>
                </div>
              </b-td>
              <b-td>
                {{ matter_evaluation.work_type }}
              </b-td>
              <b-td>
                {{ matter_evaluation.study_environments }}
              </b-td>
              <b-td>
                {{ matter_evaluation.examiner }}
              </b-td>
            </b-tr>
          </template>
          <template v-else-if="learning_evaluation.length == 0">
            <b-tr>
              <b-td colspan="12">
                <strong> No hay datos para mostrar </strong>
              </b-td>
            </b-tr>
          </template>
        </b-tbody>
      </b-table-simple>
    </div>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
export default {
  name: "LearningEvaluationView",
  components: {},
  props: {},
  data() {
    return {
      egress_profile_matter_id: Number(
        this.$route.params.egress_profile_matter_id
      ),
      learning_evaluation: [],
    };
  },
  computed: {
    ...mapGetters({
      egress_profiles_matters: names.EGRESS_PROFILES_MATTERS,
      matters: names.MATTERS,
    }),
    matter() {
      if (!this.egressProfilesMatter) return null;
      return this.matters.find((x) => x.id == this.egressProfilesMatter.matter);
    },
    egressProfilesMatter() {
      if (!this.egress_profile_matter_id) return null;
      return this.egress_profiles_matters.find(
        (x) => x.id == this.egress_profile_matter_id
      );
    },
  },
  methods: {
    LearningSuitcasePageTwo(matter_id) {
      if (matter_id)
        this.$restful
          .Get(
            `/teaching/learning_suitcase_page_two_excel/?egress_profile_matter=${this.egress_profile_matter_id}`
          )
          .then((response) => {
            if (response && response.file_url)
              window.open(response.file_url, "_blank");
          });
    },
  },
  beforeDestroy() {
    this.$store.commit(names.RESET_TITLE_OF_THE_PRINT);
  },
  created() {
    this.$store
      .dispatch(
        names.FETCH_EGRESS_PROFILE_MATTER,
        this.egress_profile_matter_id
      )
      .then((response) => {
        if (response && response.matter) {
          this.$restful
            .Get(
              `/teaching/learning_suitcase_page_two_web/?egress_profile_matter=${this.egress_profile_matter_id}`
            )
            .then((learning_evaluation) => {
              this.learning_evaluation = learning_evaluation;
            });
          this.$store
            .dispatch(names.FETCH_MATTER, response.matter)
            .then((matter) => {
              this.$store.commit(
                names.MUTATE_TITLE_OF_THE_PRINT,
                `Evaluaciones - ${matter.code} ${matter.name}`
              );
            });
        }
      });
  },
};
</script>
<style scoped src="@/utils/rich_text_editor.css">
</style>
<style scoped>
.description-evaluation {
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
}
.learning-suitcase-btn {
  margin-left: auto;
  margin-right: 0;
}
.txt-custom {
  font-size: 9pt;
}
.primary-header {
  background: black !important;
  color: white !important;
  font-weight: bold !important;
  font-size: 26px;
}
.secondary-header {
  background: #d0cece !important;
}
@media print {
  .badge {
    border: none !important;
  }
  .learning-experiences-container {
    zoom: 52%;
    display: table;
    table-layout: inherit;
  }
}
</style>